var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-form",
    { on: { submit: _vm.onSubmit } },
    [
      _c(
        "b-alert",
        {
          attrs: { variant: "danger", dismissible: "" },
          model: {
            value: _vm.showAlert,
            callback: function ($$v) {
              _vm.showAlert = $$v
            },
            expression: "showAlert",
          },
        },
        [_c("b", [_vm._v(_vm._s(_vm.alertMessage))])]
      ),
      _c(
        "div",
        [
          !_vm.practiceId
            ? _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "user-invite-practices",
                            label: "Practice",
                          },
                        },
                        [
                          _c(
                            "multiselect",
                            {
                              attrs: {
                                openDirection: "bottom",
                                placeholder: "Search by practice name",
                                "custom-label": _vm.practiceLabel,
                                "track-by": "practice_id",
                                options: _vm.selectablePractices,
                                loading: _vm.practicesLoading,
                                "internal-search": false,
                                "show-labels": false,
                                showNoOptions: true,
                              },
                              on: { "search-change": _vm.debouncePractices },
                              model: {
                                value: _vm.selectedPractice,
                                callback: function ($$v) {
                                  _vm.selectedPractice = $$v
                                },
                                expression: "selectedPractice",
                              },
                            },
                            [
                              _c("template", { slot: "noResult" }, [
                                _vm._v(" No practices matched your search "),
                              ]),
                              _c("template", { slot: "noOptions" }, [
                                _vm._v(" Start typing a practice name... "),
                              ]),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-form-group",
                    { attrs: { id: "given_name", label: "First name" } },
                    [
                      _c(
                        "b-input-group",
                        [
                          _c("b-form-input", {
                            model: {
                              value: _vm.form.given_name,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "given_name", $$v)
                              },
                              expression: "form.given_name",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        id: "middle_name_or_initial",
                        label: "Middle name",
                      },
                    },
                    [
                      _c(
                        "b-input-group",
                        [
                          _c("b-form-input", {
                            model: {
                              value: _vm.form.middle_name_or_initial,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "middle_name_or_initial",
                                  $$v
                                )
                              },
                              expression: "form.middle_name_or_initial",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                [
                  _c(
                    "b-form-group",
                    { attrs: { id: "family_name", label: "Last name" } },
                    [
                      _c(
                        "b-input-group",
                        [
                          _c("b-form-input", {
                            model: {
                              value: _vm.form.family_name,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "family_name", $$v)
                              },
                              expression: "form.family_name",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                [
                  _c(
                    "b-form-group",
                    { attrs: { id: "suffix", label: "Suffix" } },
                    [
                      _c(
                        "b-input-group",
                        [
                          _c("b-form-input", {
                            model: {
                              value: _vm.form.suffix,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "suffix", $$v)
                              },
                              expression: "form.suffix",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-form-group",
                    { attrs: { id: "ssn", label: "SSN" } },
                    [
                      _c(
                        "b-input-group",
                        [
                          _c("b-form-input", {
                            model: {
                              value: _vm.form.ssn,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "ssn", $$v)
                              },
                              expression: "form.ssn",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                [
                  _c(
                    "b-form-group",
                    { attrs: { id: "ptan", label: "PTAN" } },
                    [
                      _c(
                        "b-input-group",
                        [
                          _c("b-form-input", {
                            model: {
                              value: _vm.form.ptan,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "ptan", $$v)
                              },
                              expression: "form.ptan",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                [
                  _c(
                    "b-form-group",
                    { attrs: { id: "npi", label: "NPI" } },
                    [
                      _c(
                        "b-input-group",
                        [
                          _c("b-form-input", {
                            model: {
                              value: _vm.form.npi,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "npi", $$v)
                              },
                              expression: "form.npi",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                [
                  _c(
                    "b-form-group",
                    { attrs: { id: "provider_type", label: "Billing type" } },
                    [
                      _c("b-form-select", {
                        attrs: { options: _vm.types },
                        scopedSlots: _vm._u([
                          {
                            key: "first",
                            fn: function () {
                              return [
                                _c(
                                  "b-form-select-option",
                                  { attrs: { value: null, disabled: "" } },
                                  [_vm._v("Select type")]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                        model: {
                          value: _vm.form.provider_type,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "provider_type", $$v)
                          },
                          expression: "form.provider_type",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-form-group",
                    { attrs: { id: "email", label: "Email" } },
                    [
                      _c(
                        "b-input-group",
                        [
                          _c("b-form-input", {
                            attrs: { type: "email" },
                            model: {
                              value: _vm.form.email,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "email", $$v)
                              },
                              expression: "form.email",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                [
                  _c(
                    "b-form-group",
                    {
                      staticStyle: { "text-align": "left" },
                      attrs: { id: "date-of-birth", label: "Date of birth" },
                    },
                    [
                      _c("b-form-input", {
                        staticClass: "mb-4",
                        attrs: {
                          type: "date",
                          "today-button": "",
                          "reset-button": "",
                          "close-button": "",
                        },
                        model: {
                          value: _vm.form.date_of_birth,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "date_of_birth", $$v)
                          },
                          expression: "form.date_of_birth",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                [
                  _c(
                    "b-form-group",
                    {
                      staticStyle: { "text-align": "left" },
                      attrs: { id: "start-date", label: "Start date" },
                    },
                    [
                      _c("b-form-input", {
                        staticClass: "mb-4",
                        attrs: {
                          type: "date",
                          "today-button": "",
                          "reset-button": "",
                          "close-button": "",
                        },
                        model: {
                          value: _vm.form.start_date,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "start_date", $$v)
                          },
                          expression: "form.start_date",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { staticStyle: { "text-align": "right" } },
            [
              _c(
                "b-button",
                { staticClass: "avo-primary-btn", on: { click: _vm.onSubmit } },
                [_vm._v(" Create provider ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }